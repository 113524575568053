import { Currency } from "../../checkout/types/checkoutTypes"
import { ContentSection } from "../../components/sections/SimpleContent"

export enum Persona {
  none = "none",
  performance = "performance",
  recovery = "recovery",
  awakening = "awakening",
}

export enum ProductDisplay {
  all = "all",
  priceOnly = "priceOnly",
  priceAndDiscount = "priceAndDiscount",
}

export enum landingPageLayout {
  normal = "normal",
  headerOnly = "headerOnly",
  footerOnly = "footerOnly",
  noHeaderOrFooter = "noHeaderOrFooter",
}
export interface Image {
  id: string
  url: string
  alternativeText: string
  imageFile: {
    childImageSharp: {
      gatsbyImageData?: {
        backgroundColor?: string
        height?: number
        images?: {
          fallback?: {
            sizes?: string
            src?: string
            srcSet?: string
          }
          sources?: Array<{
            sizes?: string
            srcSet?: string
            type?: string
          }>
        }
        layout?: string
        width?: number
      }
    }
  }
}

export interface FeaturedVideo {
  youtubeId?: string
  youtubeMobileId?: string
}

export interface Heading {
  text?: string
  colour?: "black" | "white" | "gradient"
  align?: "center" | "left" | "right"
}

export interface SEO {
  metaTitle?: string
  metaDescription?: string
  metaImage?: Image
}
export interface PageProps {
  id: string
  slug: string
  title: string
  seo: SEO
  trackProductView: Partial<Product>
  sections: PageSection[]
}

export type formType =
  | "contact"
  | "career"
  | "emailSignUp"
  | "clinicSignUp"
  | undefined
export type EmailSignupNameOptions =
  | "hide"
  | "showDontRequire"
  | "showAndRequire"
  | undefined
export interface FormZone {
  id?: string
  core: SectionCore
  type: formType
  titleAlign?: "left" | "center" | "right"
  formTitle?: string
  formSubTitle?: string
  buttonText?: string
  buttonType?: ButtonType
  successMessage: string
  successRedirect?: NavItemProps
  columns?: number
  card?: Card[]
  emailSignupNameOptions?: EmailSignupNameOptions
}

export interface NavPage {
  id: string
  title: string
  slug: string
  pages: NavPage[]
  drawerSpacer?: boolean
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => Promise<void>
}

export interface NavItemProps {
  navItemPage?: NavPage
  navItemLandingPage?: NavPage
  navItemName: string
  sectionGroup?: number
  url?: string
}

export interface SectionCore {
  anchorTag?: string
  title?: string
  titleColor?: string
  subtitle?: string
  subtitleColor?: string
  backgroundColor?: SensaiColor
  animate: boolean
  marginTop?: number
  marginBottom?: number
  backgroundImage?: Image
  titleAlign?: "left" | "center" | "right"
  underlineTitle?: boolean
  smallSubTitle?: boolean
  divider?:
    | "none"
    | "topLine"
    | "topGradientLine"
    | "bottomLine"
    | "bottomGradientLine"
}
export interface PageSection {
  __typename: string
  id: string
  core: SectionCore
  card?: object
  content?: string
  data: []
}

export type InventoryBreakpoints = Array<{
  id: string
  breakpointText: string
  breakpointStart: number
  breakpointEnd: number
  textColor: string
}>
export interface ProductPriceObj {
  CAD_List_Price: number
  CAD_Discount: number
  USD_List_Price: number
  isBilledMonthly: boolean
  isBilledYearly: boolean
  USD_Discount: number
  depositHeading?: string
  priceHeading?: string
  stripeId: string
  stripeTestId: string
  inventoryBreakpoints?: InventoryBreakpoints
}
export interface Product {
  id: string
  stripeId?: string
  stripeTestId?: string
  slug: string
  seo?: SEO
  name: string
  featuredImage: Image
  gallery: Image[]
  sections: PageSection[]
  subHeading: string
  description: string
  shortDescription: string
  isBilledMonthly: boolean
  isBilledYearly: boolean
  CAD_List_Price: number
  CAD_Discount: number
  USD_List_Price: number
  USD_Discount: number
  depositHeading?: string
  priceHeading?: string
  postPurchaseContent?: ContentSection
  inventoryBreakpoints?: InventoryBreakpoints
}

export interface Price {
  id: string
  active: boolean
  currency: cur
  unit_amount: number
  type: "one_time" | "recurring"
  recurring?: {
    interval: "month" | "year" | "week" | "day"
  }
}

export interface CartProduct {
  id: string
  name: string
  metadata: {
    shippingRequired: string
    accountRequired: string
    avataxCode: string
    bladeSKU?: string
    trial_days?: string
  }
  prices: Record<Currency, Price | null>
}
export interface CartProductWithQuantity extends CartProduct {
  quantity: number
}

export type ButtonType =
  | "primary"
  | "primaryOutline"
  | "primaryTab"
  | "primaryTabActive"
  | "secondary"
  | "secondaryOutline"
  | "alternate"
  | "alternateOutline"
  | "text"
  | "textSecondary"
  | "link"
  | "white"
  | "whiteOutline"
export interface ButtonLink {
  url?: string
  addToCart?: boolean
  product?: {
    stripeId: string | undefined
    stripeTestId: string | undefined
    slug: string
  }
  navItemPage?: {
    id: string
    slug: string
  }
  navItemLandingPage?: {
    id: string
    slug: string
  }
  navItemName?: string
  linkText?: string
  quizId?: string
}

export interface ButtonProps {
  sectionId: string
  id?: string
  title?: string
  type: ButtonType
  link?: ButtonLink[]
  children?: React.ReactNode
  quantity?: number
  submit?: boolean
  onClick?: Function
  icon?: string
  size?: "hero" | "regular" | "control"
  disabled?: boolean
  className?: string
  gTagEvent?: string
  testId?: string
}

type StepName = "Account" | "Shipping" | "Address" | "Payment" | "Complete"

export interface StepInterface {
  complete: boolean
  active: boolean
  name: StepName
  nextText?: string
  icon: string
  stepNumber?: number
  onClick?: Function
  skip?: boolean
}

export interface Card {
  id: string
  title: string
  description: string
  animate: boolean
  iconType: string
  iconName: string
  media: Image
  button: ButtonProps
  productPriceDisplay: ProductPriceObj
  productDisplayEnum: ProductDisplay
  align?: "left" | "center"
}

export interface Step {
  id: string
  title: string
  iconType: string
  iconName: string
  state: "active" | "complete" | "pending"
}

export type SensaiColor =
  | "white"
  | "darkBlue"
  | "lightGrey"
  | "teal"
  | "purple"
  | "gradientTealPurple"
  | "gradientLightGrey"
  | "gradientTeal"
  | "gradientPurple"

export interface Testimonial {
  id: string
  avatar: Image
  author: string
  company: string
  quote: string
}

export interface UserData {
  email: string | null | undefined
  userId?: string | null | undefined
  affiliateId?: string | null | undefined
  campaignId?: string | null | undefined
}

export interface LsUser {
  customerAccount: string
  email: string
  exp: string
  firstName: string
  lastName: string
  localStorageLoaded: boolean
  token: string
  updated: string
}

export interface LandingPage {
  title: string
  persona: Persona
  landingPageSectionPosition: number
  seo: SEO
  hero: PageSection
  additionalSections: PageSection[]
  landing_page: LandingPage
  layout: landingPageLayout
  couponCode: string
  couponExpiryDays: number
}

export type AffiliateFromStrapi = {
  code: string
  duration: number
  expiry: string
  id: string
  name: string
  published_at: string
}

export type CampaignFromStrapi = {
  code: string
  duration: number
  expiry: string
  id: string
  name: string
  source: string
  published_at: string
}

export type UserAddress = {
  firstName: string
  lastName: string
  cardHolderName: string
  city: string
  country: string
  id: string
  postal: string
  province: string
  street: string
  unit: string
  phone: string
  vat: string
}
export interface ItemFromCms {
  id: string
  name: string
  code: string
  expiry: string
  duration: number // days
  source?: string
}

export interface PurchaseItem {
  priceId: string
  quantity: number
}

export type Article = {
  previewOnly
  author: {
    id: string
    firstName: string
    lastName: string
    avatar: { url: string }
  }
  title: string
  content: ContentSection
  id: string
  image: Image
  published_at: string
  seo: SEO
  slug: string
  articleTag: {
    tagName: string
  }
  articleDisclaimer: {
    title: string
    content: string
  }
}

export interface PaymentIntentResponse {
  clientSecret: string
  confirmationCode: string
  currency?: Currency
  totalAmount?: number
}
