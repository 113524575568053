import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { getSrc } from "gatsby-plugin-image"

import SEO from "../components/Seo"
import Layout from "../components/Layout"
import Hero from "../components/sections/Hero"
import Section from "../components/Sections"
import {
  ItemFromCms,
  LandingPage as LandingPageInterface,
  landingPageLayout,
} from "../ts/interfaces"
import { storeCampaignAffiliateData } from "../utils/queryParams"
import { setLs } from "../utils/localStorage"
import { runFreshChatLogic, runFreshDeskLogic } from "../utils/freshDesk"
import { LoadCampaignAffiliateData } from "../utils/campaignAffiliateData"

const LandingPage = ({
  pageContext,
  data: {
    strapi: {
      freshDeskWidget,
      freshChatWidget,
      affiliates,
      campaigns,
      landingPage,
    },
  },
}: {
  pageContext: {
    affiliateCode: string
    campaignCode: string
    canonicalUrls?: string[]
    slug: string
  }
  data: {
    strapi: {
      affiliates: ItemFromCms[]
      campaigns: ItemFromCms[]
      landingPage: LandingPageInterface
      freshDeskWidget: {
        freshDeskTestId?: string
        freshDeskId?: string
        openOnLoad: boolean
        pages: {
          slug?: string
        }[]
      }
      freshChatWidget: {
        freshChatToken?: string
        openOnLoad: boolean
        pages: {
          slug?: string
        }[]
      }
    }
  }
}) => {
  LoadCampaignAffiliateData()
  const {
    persona,
    landingPageSectionPosition,
    hero: landingHero,
    additionalSections,
    landing_page,
    layout,
    couponCode,
    couponExpiryDays,
  } = landingPage
  const { affiliateCode, campaignCode } = pageContext

  const {
    freshDeskId,
    freshDeskTestId,
    pages: freshDeskPages,
    openOnLoad: freshDeskOpenOnLoad,
  } = freshDeskWidget

  const {
    freshChatToken,
    pages: freshChatPages,
    openOnLoad: freshChatOpenOnLoad,
  } = freshChatWidget

  useEffect(() => {
    const freshDeskPagesFormatted = freshDeskPages.map(page => page.slug!) || []
    runFreshDeskLogic({
      currentPageSlug: pageContext.slug!,
      freshDeskPages: freshDeskPagesFormatted,
      freshDeskId,
      freshDeskTestId,
      openOnLoad: freshDeskOpenOnLoad,
    })

    const freshChatPagesFormatted = freshChatPages.map(page => page.slug!) || []

    runFreshChatLogic({
      currentPageSlug: pageContext.slug!,
      freshDeskPages: freshChatPagesFormatted,
      freshChatToken,
      openOnLoad: freshChatOpenOnLoad,
    })

    if (couponCode) {
      let expiry = new Date()

      if (couponExpiryDays) {
        expiry.setDate(expiry.getDate() + couponExpiryDays)
      } else {
        expiry.setDate(expiry.getDate() + 365)
      }

      setLs("coupon", { id: couponCode, expiry: expiry.toISOString() })
    }
  }, [])

  if (affiliateCode) {
    storeCampaignAffiliateData({
      queryParamId: affiliateCode,
      localStorageName: "affiliate",
      affiliates,
      campaigns,
    })
  }
  if (campaignCode) {
    storeCampaignAffiliateData({
      queryParamId: campaignCode,
      localStorageName: "campaign",
      affiliates,
      campaigns,
    })
  }
  if (persona !== "none") {
    setLs("persona", { id: persona })
  }

  const hero =
    landingHero ||
    (landing_page &&
      landing_page.additionalSections.find(
        data => data.__typename === "STRAPI_ComponentZonesHeroZone"
      )) ||
    null

  const hasHero = hero !== null && hero !== undefined

  const showFooter =
    layout &&
    (layout === landingPageLayout["normal"] ||
      layout === landingPageLayout["footerOnly"])

  const showHeader =
    layout &&
    (layout === landingPageLayout["normal"] ||
      layout === landingPageLayout["headerOnly"])

  const hasRelatedLandingPageSectionIndex =
    landingPageSectionPosition !== null &&
    landingPageSectionPosition !== undefined

  return (
    <Layout
      hasHero={hasHero}
      showStickFooter={showFooter}
      showFooter={showFooter}
      showHeader={showHeader}
    >
      <SEO
        title={landingPage.title}
        metaTitle={landingPage.seo.metaTitle}
        description={landingPage.seo.metaDescription}
        image={getSrc(
          landingPage.seo.metaImage?.imageFile.childImageSharp.gatsbyImageData
        )}
        canonicalUrls={pageContext.canonicalUrls}
      />
      {hasHero && <Hero {...hero} />}

      {additionalSections &&
        additionalSections.map((section, index) => (
          <>
            {hasRelatedLandingPageSectionIndex &&
              landingPageSectionPosition === index &&
              landing_page &&
              landing_page.additionalSections.map((section, innerIndex) => (
                <Section
                  key={`${section.__typename}-${section.id}`}
                  section={{
                    ...section,
                  }}
                  delayIndex={hasHero ? index : undefined}
                />
              ))}
            <Section
              key={`${section.__typename}-${section.id}`}
              section={{
                ...section,
              }}
              delayIndex={hasHero ? index : undefined}
            />
          </>
        ))}

      {!hasRelatedLandingPageSectionIndex &&
        landing_page &&
        landing_page.additionalSections.map((section, index) => (
          <Section
            key={`${section.__typename}-${section.id}`}
            section={{
              ...section,
            }}
            delayIndex={hasHero ? index : undefined}
          />
        ))}
    </Layout>
  )
}

export default LandingPage

export const LandingPageQuery = graphql`
  query LandingPageById($id: ID!) {
    strapi {
      freshDeskWidget {
        freshDeskTestId
        freshDeskId
        openOnLoad
        pages {
          slug
        }
      }
      freshChatWidget {
        freshChatToken
        openOnLoad
        pages {
          slug
        }
      }
      affiliates {
        id
        name
        code
        expiry
        duration
      }
      campaigns {
        id
        name
        code
        source
        expiry
        duration
      }
      landingPage(id: $id) {
        id
        title
        slug
        persona
        layout
        landingPageSectionPosition
        couponCode
        couponExpiryDays
        seo {
          id
          metaTitle
          metaDescription
          metaImage {
            url
            imageFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: NONE
                  layout: FIXED
                  quality: 80
                  width: 1200
                )
              }
            }
          }
        }
        hero {
          id
          backgroundImage {
            url
            alternativeText
            imageFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: NONE
                  layout: FULL_WIDTH
                  quality: 80
                )
              }
            }
          }
          mobileBackgroundImage {
            url
            alternativeText
            imageFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: NONE
                  layout: FULL_WIDTH
                  quality: 30
                  breakpoints: [400, 600, 750, 1080]
                )
              }
            }
          }
          button {
            button {
              id
              title
              type
              link {
                __typename
                ... on STRAPI_ComponentNavigationMainNav {
                  navItemPage {
                    id
                    slug
                  }
                  navItemLandingPage {
                    id
                    slug
                  }
                  navItemName
                }
                ... on STRAPI_ComponentNavigationProduct {
                  id
                  addToCart
                  product {
                    id
                    stripeId
                    stripeTestId
                    slug
                  }
                  linkText
                }
                ... on STRAPI_ComponentNavigationHyperlink {
                  url
                }
                ... on STRAPI_ComponentZonesQuiz {
                  id
                  quizId
                }
              }
            }
          }
          heading {
            text
            colour
            align
          }
          subheading {
            text
            colour
            align
          }
          contentAlign
          displayType
          featuredVideo {
            id
            youtubeId
            youtubeMobileId
          }
        }
        additionalSections {
          __typename
          ... on STRAPI_ComponentZonesPrivacyAndTermsZone {
            id
            policyType
          }
          ... on STRAPI_ComponentZonesTableZone {
            id
            table {
              name
              showTitle
              rows {
                title
                cells {
                  content
                }
              }
              columns {
                title
                highlighted
              }
            }
          }
          ... on STRAPI_ComponentZonesCarouselZone {
            id
            images {
              url
              alternativeText
              imageFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: NONE
                    layout: FULL_WIDTH
                    quality: 80
                  )
                }
              }
            }
          }
          ... on STRAPI_ComponentZonesBannerZone {
            id
            banner {
              linkToPage {
                id
                slug
              }
              linkToLandingPage {
                id
                slug
              }
              linkToExternalPage
              backgroundColor
              marginTop
              marginBottom
              paddingTop
              paddingBottom
              image {
                url
                alternativeText
                imageFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: NONE
                      layout: FULL_WIDTH
                      quality: 80
                    )
                  }
                }
              }
            }
          }
          ... on STRAPI_ComponentZonesEmailConfirmZone {
            id
            title
            buttonText
            instructionParagraph
            resendTokenText
            redirectPage {
              id
              slug
            }
            redirectLandingPage {
              id
              slug
            }
          }
          ... on STRAPI_ComponentZonesHeroZone {
            id
            heading {
              text
              colour
              align
            }
            subheading {
              text
              colour
              align
            }
            more
            contentAlign
            displayType
            backgroundImage {
              url
              alternativeText
              imageFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: NONE
                    layout: FULL_WIDTH
                    quality: 80
                  )
                }
              }
            }
            mobileBackgroundImage {
              url
              alternativeText
              imageFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: NONE
                    layout: FULL_WIDTH
                    quality: 30
                    breakpoints: [400, 600, 750, 1080]
                  )
                }
              }
            }
            featuredVideo {
              youtubeId
              youtubeMobileId
            }
            button {
              button {
                id
                title
                type
                link {
                  __typename
                  ... on STRAPI_ComponentNavigationMainNav {
                    navItemPage {
                      id
                      slug
                    }
                    navItemLandingPage {
                      id
                      slug
                    }
                    navItemName
                  }
                  ... on STRAPI_ComponentNavigationProduct {
                    id
                    addToCart
                    product {
                      id
                      stripeId
                      stripeTestId
                      slug
                    }
                    linkText
                  }
                  ... on STRAPI_ComponentNavigationHyperlink {
                    url
                  }
                  ... on STRAPI_ComponentZonesQuiz {
                    id
                    quizId
                  }
                }
              }
            }
          }
          ... on STRAPI_ComponentZonesFormZone {
            id
            type
            formTitle
            formSubTitle
            buttonText
            buttonType
            successMessage
            emailSignupNameOptions
            successRedirect {
              navItemPage {
                id
                title
                slug
              }
              navItemLandingPage {
                id
                title
                slug
              }
            }
            columns

            card {
              id
              title
              description
              iconName
              iconType
              align
              media {
                url
                alternativeText
                imageFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: NONE
                      layout: FULL_WIDTH
                      quality: 80
                    )
                  }
                }
              }
              productPriceDisplay {
                CAD_List_Price
                CAD_Discount
                USD_List_Price
                USD_Discount
                depositHeading
                priceHeading
                stripeId
                stripeTestId
                inventoryBreakpoints {
                  id
                  breakpointText
                  breakpointStart
                  breakpointEnd
                  textColor
                }
              }
              productDisplayEnum
              button {
                id
                title
                type
                link {
                  __typename
                  ... on STRAPI_ComponentNavigationMainNav {
                    navItemPage {
                      id
                      slug
                    }
                    navItemLandingPage {
                      id
                      slug
                    }
                    navItemName
                  }
                  ... on STRAPI_ComponentNavigationProduct {
                    id
                    addToCart
                    product {
                      id
                      stripeId
                      stripeTestId
                      slug
                    }
                    linkText
                  }
                  ... on STRAPI_ComponentNavigationHyperlink {
                    url
                  }
                  ... on STRAPI_ComponentZonesQuiz {
                    id
                    quizId
                  }
                }
              }
            }
            core {
              anchorTag
              title
              titleColor
              subtitle
              subtitleColor
              backgroundColor
              animate
              marginTop
              marginBottom
              underlineTitle
              smallSubTitle
              titleAlign
              divider
              backgroundImage {
                url
                alternativeText
                imageFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: NONE
                      layout: FULL_WIDTH
                      quality: 80
                    )
                  }
                }
              }
            }
          }
          ... on STRAPI_ComponentZonesCardZone {
            id
            columns
            maxWidth
            core {
              anchorTag
              title
              titleColor
              subtitle
              subtitleColor
              backgroundColor
              animate
              marginTop
              marginBottom
              underlineTitle
              smallSubTitle
              titleAlign
              divider
              backgroundImage {
                url
                alternativeText
                imageFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: NONE
                      layout: FULL_WIDTH
                      quality: 80
                    )
                  }
                }
              }
            }
            card {
              id
              title
              description
              iconName
              iconType
              align
              media {
                url
                alternativeText
                imageFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: NONE
                      layout: CONSTRAINED
                      quality: 80
                    )
                  }
                }
              }
              productPriceDisplay {
                CAD_List_Price
                CAD_Discount
                USD_List_Price
                USD_Discount
                depositHeading
                priceHeading
                stripeId
                stripeTestId
                inventoryBreakpoints {
                  id
                  breakpointText
                  breakpointStart
                  breakpointEnd
                  textColor
                }
              }
              productDisplayEnum
              button {
                id
                title
                type
                link {
                  __typename
                  ... on STRAPI_ComponentNavigationMainNav {
                    navItemPage {
                      id
                      slug
                    }
                    navItemLandingPage {
                      id
                      slug
                    }
                    navItemName
                  }
                  ... on STRAPI_ComponentNavigationProduct {
                    id
                    addToCart
                    product {
                      id
                      stripeId
                      stripeTestId
                      slug
                    }
                    linkText
                  }
                  ... on STRAPI_ComponentNavigationHyperlink {
                    url
                  }
                  ... on STRAPI_ComponentZonesQuiz {
                    id
                    quizId
                  }
                }
              }
            }
          }
          ... on STRAPI_ComponentZonesSpecZone {
            id
            core {
              anchorTag
              title
              titleColor
              subtitle
              subtitleColor
              backgroundColor
              animate
              marginTop
              marginBottom
              underlineTitle
              smallSubTitle
              titleAlign
              divider
              backgroundImage {
                url
                alternativeText
                imageFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: NONE
                      layout: FULL_WIDTH
                      quality: 80
                    )
                  }
                }
              }
            }
            specCard {
              id
              title
              description
              countTo
              countSuffix
              strikethrough
              countPrefix
              countLabel
            }
          }
          ... on STRAPI_ComponentZonesStepsZone {
            id
            description
            core {
              anchorTag
              title
              titleColor
              subtitle
              subtitleColor
              backgroundColor
              animate
              marginTop
              marginBottom
              underlineTitle
              smallSubTitle
              titleAlign
              divider
              backgroundImage {
                url
                alternativeText
                imageFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: NONE
                      layout: FULL_WIDTH
                      quality: 80
                    )
                  }
                }
              }
            }
            steps {
              title
              iconName
              iconType
              state
            }
            buttons {
              button {
                id
                title
                type
                link {
                  __typename
                  ... on STRAPI_ComponentNavigationMainNav {
                    navItemPage {
                      id
                      slug
                    }
                    navItemLandingPage {
                      id
                      slug
                    }
                    navItemName
                  }
                  ... on STRAPI_ComponentNavigationProduct {
                    id
                    addToCart
                    product {
                      id
                      stripeId
                      stripeTestId
                      slug
                    }
                    linkText
                  }
                  ... on STRAPI_ComponentNavigationHyperlink {
                    url
                  }
                  ... on STRAPI_ComponentZonesQuiz {
                    id
                    quizId
                  }
                }
              }
            }
          }
          ... on STRAPI_ComponentZonesSimpleContentZone {
            id
            content
            align
            alignButtons
            maxWidth
            largeFormat
            inlineMedia {
              referenceName
              rounded
              align
              width
              youtubeId
              youtubeMobileId
              image {
                url
                alternativeText
                imageFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: NONE
                      layout: FULL_WIDTH
                      quality: 80
                    )
                  }
                }
              }
              mobileImage {
                url
                alternativeText
                imageFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: NONE
                      layout: FULL_WIDTH
                      quality: 80
                    )
                  }
                }
              }
            }
            buttons {
              button {
                id
                title
                type
                link {
                  __typename
                  ... on STRAPI_ComponentNavigationMainNav {
                    navItemPage {
                      id
                      slug
                    }
                    navItemLandingPage {
                      id
                      slug
                    }
                    navItemName
                  }
                  ... on STRAPI_ComponentNavigationProduct {
                    id
                    addToCart
                    product {
                      id
                      stripeId
                      stripeTestId
                      slug
                    }
                    linkText
                  }
                  ... on STRAPI_ComponentNavigationHyperlink {
                    url
                  }
                  ... on STRAPI_ComponentZonesQuiz {
                    id
                    quizId
                  }
                }
              }
            }

            core {
              anchorTag
              title
              titleColor
              subtitle
              subtitleColor
              backgroundColor
              animate
              marginTop
              marginBottom
              underlineTitle
              smallSubTitle
              titleAlign
              divider
              backgroundImage {
                url
                alternativeText
                imageFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: NONE
                      layout: FULL_WIDTH
                      quality: 80
                    )
                  }
                }
              }
            }
            featuredImage {
              url
              alternativeText
              imageFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: NONE
                    layout: FULL_WIDTH
                    quality: 70
                  )
                }
              }
            }
            featuredVideo {
              youtubeId
              youtubeMobileId
            }
          }
          ... on STRAPI_ComponentZonesTestimonialsZone {
            id
            core {
              anchorTag
              title
              titleColor
              subtitle
              subtitleColor
              backgroundColor
              animate
              marginTop
              marginBottom
              underlineTitle
              smallSubTitle
              titleAlign
              divider
              backgroundImage {
                url
                alternativeText
                imageFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: NONE
                      layout: FULL_WIDTH
                      quality: 80
                    )
                  }
                }
              }
            }
            version
            start
            testimonials {
              id
              avatar {
                url
                alternativeText
                imageFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: NONE
                      layout: FIXED
                      quality: 80
                      height: 132
                      width: 132
                    )
                  }
                }
              }
              author
              company
              quote
            }
          }
          ... on STRAPI_ComponentZonesFeaturesZone {
            id
            core {
              anchorTag
              title
              titleColor
              subtitle
              subtitleColor
              backgroundColor
              animate
              marginTop
              marginBottom
              underlineTitle
              smallSubTitle
              titleAlign
              divider
              backgroundImage {
                url
                alternativeText
                imageFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: NONE
                      layout: FULL_WIDTH
                      quality: 80
                    )
                  }
                }
              }
            }
            description
            image {
              url
              alternativeText
              imageFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: NONE
                    layout: FULL_WIDTH
                    quality: 80
                  )
                }
              }
            }
            cards {
              id
              title
              iconType
              iconName
              description
              align
              media {
                url
                alternativeText
                imageFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: NONE
                      layout: FULL_WIDTH
                      quality: 80
                    )
                  }
                }
              }
              productPriceDisplay {
                CAD_List_Price
                CAD_Discount
                USD_List_Price
                USD_Discount
                depositHeading
                priceHeading
                stripeId
                stripeTestId
                inventoryBreakpoints {
                  id
                  breakpointText
                  breakpointStart
                  breakpointEnd
                  textColor
                }
              }
              productDisplayEnum
              button {
                id
                title
                type
                link {
                  __typename
                  ... on STRAPI_ComponentNavigationMainNav {
                    navItemPage {
                      id
                      slug
                    }
                    navItemLandingPage {
                      id
                      slug
                    }
                    navItemName
                  }
                  ... on STRAPI_ComponentNavigationProduct {
                    id
                    addToCart
                    product {
                      id
                      stripeId
                      stripeTestId
                      slug
                    }
                    linkText
                  }
                  ... on STRAPI_ComponentNavigationHyperlink {
                    url
                  }
                  ... on STRAPI_ComponentZonesQuiz {
                    id
                    quizId
                  }
                }
              }
            }
          }
          ... on STRAPI_ComponentZonesInteractiveFeaturesZone {
            id
            core {
              anchorTag
              title
              titleColor
              subtitle
              subtitleColor
              backgroundColor
              animate
              marginTop
              marginBottom
              underlineTitle
              smallSubTitle
              titleAlign
              divider
              backgroundImage {
                url
                alternativeText
                imageFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: NONE
                      layout: FULL_WIDTH
                      quality: 80
                    )
                  }
                }
              }
            }
            description
            cards {
              id
              title
              iconType
              iconName
              description
              align
              media {
                url
                alternativeText
                imageFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: NONE
                      layout: FULL_WIDTH
                      quality: 80
                    )
                  }
                }
              }
              productPriceDisplay {
                CAD_List_Price
                CAD_Discount
                USD_List_Price
                USD_Discount
                depositHeading
                priceHeading
                stripeId
                stripeTestId
                inventoryBreakpoints {
                  id
                  breakpointText
                  breakpointStart
                  breakpointEnd
                  textColor
                }
              }
              productDisplayEnum
              button {
                id
                title
                type
                link {
                  __typename
                  ... on STRAPI_ComponentNavigationMainNav {
                    navItemPage {
                      id
                      slug
                    }
                    navItemLandingPage {
                      id
                      slug
                    }
                    navItemName
                  }
                  ... on STRAPI_ComponentNavigationProduct {
                    id
                    addToCart
                    product {
                      id
                      stripeId
                      stripeTestId
                      slug
                    }
                    linkText
                  }
                  ... on STRAPI_ComponentNavigationHyperlink {
                    url
                  }
                  ... on STRAPI_ComponentZonesQuiz {
                    id
                    quizId
                  }
                }
              }
            }
          }
          ... on STRAPI_ComponentZonesHeaderZone {
            id
            title
            subtitle
            alignHeader
            maxWidth
            titleColor
          }
        }
        affiliate {
          id
          name
          code
          expiry
          duration
        }
        campaign {
          id
          name
          code
          source
          expiry
          duration
        }
        landing_page {
          id
          title
          slug
          seo {
            metaTitle
            metaDescription
            metaImage {
              url
              imageFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: NONE
                    layout: FIXED
                    quality: 80
                    width: 1200
                  )
                }
              }
            }
          }
          additionalSections {
            __typename
            ... on STRAPI_ComponentZonesHeroZone {
              id
              heading {
                text
                colour
                align
              }
              subheading {
                text
                colour
                align
              }
              more
              contentAlign
              displayType
              backgroundImage {
                url
                alternativeText
                imageFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: NONE
                      layout: FULL_WIDTH
                      quality: 80
                    )
                  }
                }
              }
              mobileBackgroundImage {
                url
                alternativeText
                imageFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: NONE
                      layout: FULL_WIDTH
                      quality: 30
                      breakpoints: [400, 600, 750, 1080]
                    )
                  }
                }
              }
              featuredVideo {
                youtubeId
                youtubeMobileId
              }
              button {
                button {
                  id
                  title
                  type
                  link {
                    __typename
                    ... on STRAPI_ComponentNavigationMainNav {
                      navItemPage {
                        id
                        slug
                      }
                      navItemLandingPage {
                        id
                        slug
                      }
                      navItemName
                    }
                    ... on STRAPI_ComponentNavigationProduct {
                      id
                      addToCart
                      product {
                        id
                        stripeId
                        stripeTestId
                        slug
                      }
                      linkText
                    }
                    ... on STRAPI_ComponentNavigationHyperlink {
                      url
                    }
                    ... on STRAPI_ComponentZonesQuiz {
                      id
                      quizId
                    }
                  }
                }
              }
            }
            ... on STRAPI_ComponentZonesFormZone {
              id
              type
              formTitle
              formSubTitle
              buttonText
              buttonType
              successMessage
              emailSignupNameOptions
              successRedirect {
                navItemPage {
                  id
                  title
                  slug
                }
                navItemLandingPage {
                  id
                  title
                  slug
                }
              }
              columns

              card {
                id
                title
                description
                iconName
                iconType
                align
                media {
                  url
                  alternativeText
                  imageFile {
                    childImageSharp {
                      gatsbyImageData(
                        placeholder: NONE
                        layout: FULL_WIDTH
                        quality: 80
                      )
                    }
                  }
                }
                productPriceDisplay {
                  CAD_List_Price
                  CAD_Discount
                  USD_List_Price
                  USD_Discount
                  depositHeading
                  priceHeading
                  stripeId
                  stripeTestId
                  inventoryBreakpoints {
                    id
                    breakpointText
                    breakpointStart
                    breakpointEnd
                    textColor
                  }
                }
                productDisplayEnum
                button {
                  id
                  title
                  type
                  link {
                    __typename
                    ... on STRAPI_ComponentNavigationMainNav {
                      navItemPage {
                        id
                        slug
                      }
                      navItemLandingPage {
                        id
                        slug
                      }
                      navItemName
                    }
                    ... on STRAPI_ComponentNavigationProduct {
                      id
                      addToCart
                      product {
                        id
                        stripeId
                        stripeTestId
                        slug
                      }
                      linkText
                    }
                    ... on STRAPI_ComponentNavigationHyperlink {
                      url
                    }
                    ... on STRAPI_ComponentZonesQuiz {
                      id
                      quizId
                    }
                  }
                }
              }
              core {
                anchorTag
                title
                titleColor
                subtitle
                subtitleColor
                backgroundColor
                animate
                marginTop
                marginBottom
                underlineTitle
                smallSubTitle
                titleAlign
                divider
                backgroundImage {
                  url
                  alternativeText
                  imageFile {
                    childImageSharp {
                      gatsbyImageData(
                        placeholder: NONE
                        layout: FULL_WIDTH
                        quality: 80
                      )
                    }
                  }
                }
              }
            }
            ... on STRAPI_ComponentZonesCardZone {
              id
              columns
              maxWidth
              core {
                anchorTag
                title
                titleColor
                subtitle
                subtitleColor
                backgroundColor
                animate
                marginTop
                marginBottom
                underlineTitle
                smallSubTitle
                titleAlign
                divider
                backgroundImage {
                  url
                  alternativeText
                  imageFile {
                    childImageSharp {
                      gatsbyImageData(
                        placeholder: NONE
                        layout: FULL_WIDTH
                        quality: 80
                      )
                    }
                  }
                }
              }
              card {
                id
                title
                description
                iconName
                iconType
                align
                media {
                  url
                  alternativeText
                  imageFile {
                    childImageSharp {
                      gatsbyImageData(
                        placeholder: NONE
                        layout: CONSTRAINED
                        quality: 80
                      )
                    }
                  }
                }
                productPriceDisplay {
                  CAD_List_Price
                  CAD_Discount
                  USD_List_Price
                  USD_Discount
                  depositHeading
                  priceHeading
                  stripeId
                  stripeTestId
                  inventoryBreakpoints {
                    id
                    breakpointText
                    breakpointStart
                    breakpointEnd
                    textColor
                  }
                }
                productDisplayEnum
                button {
                  id
                  title
                  type
                  link {
                    __typename
                    ... on STRAPI_ComponentNavigationMainNav {
                      navItemPage {
                        id
                        slug
                      }
                      navItemLandingPage {
                        id
                        slug
                      }
                      navItemName
                    }
                    ... on STRAPI_ComponentNavigationProduct {
                      id
                      addToCart
                      product {
                        id
                        stripeId
                        stripeTestId
                        slug
                      }
                      linkText
                    }
                    ... on STRAPI_ComponentNavigationHyperlink {
                      url
                    }
                    ... on STRAPI_ComponentZonesQuiz {
                      id
                      quizId
                    }
                  }
                }
              }
            }
            ... on STRAPI_ComponentZonesSpecZone {
              id
              core {
                anchorTag
                title
                titleColor
                subtitle
                subtitleColor
                backgroundColor
                animate
                marginTop
                marginBottom
                underlineTitle
                smallSubTitle
                titleAlign
                divider
                backgroundImage {
                  url
                  alternativeText
                  imageFile {
                    childImageSharp {
                      gatsbyImageData(
                        placeholder: NONE
                        layout: FULL_WIDTH
                        quality: 80
                      )
                    }
                  }
                }
              }
              specCard {
                id
                title
                description
                countTo
                countSuffix
                strikethrough
                countPrefix
                countLabel
              }
            }
            ... on STRAPI_ComponentZonesStepsZone {
              id
              description
              core {
                anchorTag
                title
                titleColor
                subtitle
                subtitleColor
                backgroundColor
                animate
                marginTop
                marginBottom
                underlineTitle
                smallSubTitle
                titleAlign
                divider
                backgroundImage {
                  url
                  alternativeText
                  imageFile {
                    childImageSharp {
                      gatsbyImageData(
                        placeholder: NONE
                        layout: FULL_WIDTH
                        quality: 80
                      )
                    }
                  }
                }
              }
              steps {
                title
                iconName
                iconType
                state
              }
              buttons {
                button {
                  id
                  title
                  type
                  link {
                    __typename
                    ... on STRAPI_ComponentNavigationMainNav {
                      navItemPage {
                        id
                        slug
                      }
                      navItemLandingPage {
                        id
                        slug
                      }
                      navItemName
                    }
                    ... on STRAPI_ComponentNavigationProduct {
                      id
                      addToCart
                      product {
                        id
                        stripeId
                        stripeTestId
                        slug
                      }
                      linkText
                    }
                    ... on STRAPI_ComponentNavigationHyperlink {
                      url
                    }
                    ... on STRAPI_ComponentZonesQuiz {
                      id
                      quizId
                    }
                  }
                }
              }
            }
            ... on STRAPI_ComponentZonesSimpleContentZone {
              id
              content
              align
              alignButtons
              maxWidth
              largeFormat
              inlineMedia {
                referenceName
                rounded
                align
                width
                youtubeId
                youtubeMobileId
                image {
                  url
                  alternativeText
                  imageFile {
                    childImageSharp {
                      gatsbyImageData(
                        placeholder: NONE
                        layout: FULL_WIDTH
                        quality: 80
                      )
                    }
                  }
                }
                mobileImage {
                  url
                  alternativeText
                  imageFile {
                    childImageSharp {
                      gatsbyImageData(
                        placeholder: NONE
                        layout: FULL_WIDTH
                        quality: 80
                      )
                    }
                  }
                }
              }
              buttons {
                button {
                  id
                  title
                  type
                  link {
                    __typename
                    ... on STRAPI_ComponentNavigationMainNav {
                      navItemPage {
                        id
                        slug
                      }
                      navItemLandingPage {
                        id
                        slug
                      }
                      navItemName
                    }
                    ... on STRAPI_ComponentNavigationProduct {
                      id
                      addToCart
                      product {
                        id
                        stripeId
                        stripeTestId
                        slug
                      }
                      linkText
                    }
                    ... on STRAPI_ComponentNavigationHyperlink {
                      url
                    }
                    ... on STRAPI_ComponentZonesQuiz {
                      id
                      quizId
                    }
                  }
                }
              }
              core {
                anchorTag
                title
                titleColor
                subtitle
                subtitleColor
                backgroundColor
                animate
                marginTop
                marginBottom
                underlineTitle
                smallSubTitle
                titleAlign
                divider
                backgroundImage {
                  url
                  alternativeText
                  imageFile {
                    childImageSharp {
                      gatsbyImageData(
                        placeholder: NONE
                        layout: FULL_WIDTH
                        quality: 80
                      )
                    }
                  }
                }
              }
              featuredImage {
                url
                alternativeText
                imageFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: NONE
                      layout: FULL_WIDTH
                      quality: 70
                    )
                  }
                }
              }
              featuredVideo {
                youtubeId
                youtubeMobileId
              }
            }
            ... on STRAPI_ComponentZonesTestimonialsZone {
              id
              core {
                anchorTag
                title
                titleColor
                subtitle
                subtitleColor
                backgroundColor
                animate
                marginTop
                marginBottom
                underlineTitle
                smallSubTitle
                titleAlign
                divider
                backgroundImage {
                  url
                  alternativeText
                  imageFile {
                    childImageSharp {
                      gatsbyImageData(
                        placeholder: NONE
                        layout: FULL_WIDTH
                        quality: 80
                      )
                    }
                  }
                }
              }
              version
              start
              testimonials {
                id
                avatar {
                  url
                  alternativeText
                  imageFile {
                    childImageSharp {
                      gatsbyImageData(
                        placeholder: NONE
                        layout: FIXED
                        quality: 80
                        height: 132
                        width: 132
                      )
                    }
                  }
                }
                author
                company
                quote
              }
            }
            ... on STRAPI_ComponentZonesFeaturesZone {
              id
              core {
                anchorTag
                title
                titleColor
                subtitle
                subtitleColor
                backgroundColor
                animate
                marginTop
                marginBottom
                underlineTitle
                smallSubTitle
                titleAlign
                divider
                backgroundImage {
                  url
                  alternativeText
                  imageFile {
                    childImageSharp {
                      gatsbyImageData(
                        placeholder: NONE
                        layout: FULL_WIDTH
                        quality: 80
                      )
                    }
                  }
                }
              }
              description
              image {
                url
                alternativeText
                imageFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: NONE
                      layout: FULL_WIDTH
                      quality: 80
                    )
                  }
                }
              }
              cards {
                id
                title
                iconType
                iconName
                description
                align
                media {
                  url
                  alternativeText
                  imageFile {
                    childImageSharp {
                      gatsbyImageData(
                        placeholder: NONE
                        layout: FULL_WIDTH
                        quality: 80
                      )
                    }
                  }
                }
                productPriceDisplay {
                  CAD_List_Price
                  CAD_Discount
                  USD_List_Price
                  USD_Discount
                  depositHeading
                  priceHeading
                  stripeId
                  stripeTestId
                  inventoryBreakpoints {
                    id
                    breakpointText
                    breakpointStart
                    breakpointEnd
                    textColor
                  }
                }
                productDisplayEnum
                button {
                  id
                  title
                  type
                  link {
                    __typename
                    ... on STRAPI_ComponentNavigationMainNav {
                      navItemPage {
                        id
                        slug
                      }
                      navItemLandingPage {
                        id
                        slug
                      }
                      navItemName
                    }
                    ... on STRAPI_ComponentNavigationProduct {
                      id
                      addToCart
                      product {
                        id
                        stripeId
                        stripeTestId
                        slug
                      }
                      linkText
                    }
                    ... on STRAPI_ComponentNavigationHyperlink {
                      url
                    }
                    ... on STRAPI_ComponentZonesQuiz {
                      id
                      quizId
                    }
                  }
                }
              }
            }
            ... on STRAPI_ComponentZonesInteractiveFeaturesZone {
              id
              core {
                anchorTag
                title
                titleColor
                subtitle
                subtitleColor
                backgroundColor
                animate
                marginTop
                marginBottom
                underlineTitle
                smallSubTitle
                titleAlign
                divider
                backgroundImage {
                  url
                  alternativeText
                  imageFile {
                    childImageSharp {
                      gatsbyImageData(
                        placeholder: NONE
                        layout: FULL_WIDTH
                        quality: 80
                      )
                    }
                  }
                }
              }
              description
              cards {
                id
                title
                iconType
                iconName
                description
                align
                media {
                  url
                  alternativeText
                  imageFile {
                    childImageSharp {
                      gatsbyImageData(
                        placeholder: NONE
                        layout: FULL_WIDTH
                        quality: 80
                      )
                    }
                  }
                }
                productPriceDisplay {
                  CAD_List_Price
                  CAD_Discount
                  USD_List_Price
                  USD_Discount
                  depositHeading
                  priceHeading
                  stripeId
                  stripeTestId
                  inventoryBreakpoints {
                    id
                    breakpointText
                    breakpointStart
                    breakpointEnd
                    textColor
                  }
                }
                productDisplayEnum
                button {
                  id
                  title
                  type
                  link {
                    __typename
                    ... on STRAPI_ComponentNavigationMainNav {
                      navItemPage {
                        id
                        slug
                      }
                      navItemLandingPage {
                        id
                        slug
                      }
                      navItemName
                    }
                    ... on STRAPI_ComponentNavigationProduct {
                      id
                      addToCart
                      product {
                        id
                        stripeId
                        stripeTestId
                        slug
                      }
                      linkText
                    }
                    ... on STRAPI_ComponentNavigationHyperlink {
                      url
                    }
                    ... on STRAPI_ComponentZonesQuiz {
                      id
                      quizId
                    }
                  }
                }
              }
            }
            ... on STRAPI_ComponentZonesHeaderZone {
              id
              title
              subtitle
              alignHeader
              maxWidth
              titleColor
            }
          }
        }
      }
    }
  }
`
