declare global {
  interface Window {
    fwSettings: any
    FreshworksWidget: any
    fcSettings: any
    fcWidget: any
  }
}

export const LOAD_TIMEOUT = 4 * 1000

export const initFreshDesk = (widgetId: string): void => {
  try {
    window.fwSettings = {
      widget_id: widgetId,
      locale: "en",
    }
    if ("function" != typeof window.FreshworksWidget) {
      var n: any = function () {
        n.q.push(arguments)
      }
      ;(n.q = []), (window.FreshworksWidget = n)
    }
  } catch (error) {
    console.log(error)
  }
}

export const initFreshChat = (
  freshChatToken: string,
  openOnLoad: boolean
): void => {
  try {
    window.fcSettings = {
      token: freshChatToken,
      host: "https://wchat.freshchat.com",
      open: openOnLoad,
    }
  } catch (error) {
    console.log(error)
  }
}

export const runFreshDeskLogic = ({
  currentPageSlug,
  freshDeskPages,
  freshDeskId,
  freshDeskTestId,
  openOnLoad,
}: {
  currentPageSlug: string
  freshDeskPages: string[] | []
  freshDeskId?: string
  freshDeskTestId?: string
  openOnLoad: boolean
}) => {
  if (freshDeskPages.includes(currentPageSlug)) {
    if (!window.FreshworksWidget) {
      const id =
        process.env.GATSBY_STRIPE_ENVIRONMENT === "production"
          ? freshDeskId
          : freshDeskTestId
      if (id) {
        // we don't wait to load the widget again if it already exists
        if (window.FreshworksWidget) return

        initFreshDesk(id)

        const script = document.createElement("script")
        script.src = `https://widget.freshworks.com/widgets/${id}.js`
        script.type = "text/javascript"

        document.body.appendChild(script)
        if (openOnLoad) {
          return window.FreshworksWidget("open")
        }
      }
    }
    if (openOnLoad) {
      return window.FreshworksWidget("open")
    }
    return window.FreshworksWidget("show")
  } else {
    if (window.FreshworksWidget) {
      return window.FreshworksWidget("hide")
    }
  }
}

export const runFreshChatLogic = ({
  currentPageSlug,
  freshDeskPages,
  freshChatToken,
  openOnLoad,
}: {
  currentPageSlug: string
  freshDeskPages: string[] | []
  freshChatToken?: string
  openOnLoad: boolean
}) => {
  if (freshDeskPages.includes(currentPageSlug)) {
    if (!window.fcWidget) {
      if (freshChatToken) {
        // we don't wait to load the widget again if it already exists
        if (window.fcWidget) return

        const script = document.createElement("script")
        script.src = `https://wchat.freshchat.com/js/widget.js`
        script.type = "text/javascript"

        document.body.appendChild(script)
        return initFreshChat(freshChatToken, openOnLoad)
      }
    }
    return window.fcWidget.show()
  } else {
    if (window.fcWidget) {
      return window.fcWidget.hide()
    }
  }
}
